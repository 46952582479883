import { datadogRum } from '@datadog/browser-rum';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import frLocale from 'date-fns/locale/fr';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import packageJson from '../package.json';
import App from './App';
import { store } from './app/store';
import './index.css';
import reportWebVitals from './reportWebVitals';

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_ENVIRONMENT === 'production'
) {
  datadogRum.init({
    applicationId: '970c41d9-1d6b-4f94-81c4-032c70fe94b6',
    clientToken: 'pubd7d9a54223b5426dce52ee891826cfb9',
    site: 'datadoghq.eu',
    service: 'juno',
    env: 'production',
    version: !!packageJson?.version ? packageJson?.version : '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      'https://api.drooky.com/api/today/',
      /https:\/\/.*\.api\.drooky\.com\/api\/today\//,
      (url) => url.startsWith('https://api.drooky.com/api/today/'),
    ],
  });
}
Sentry.init({
  dsn: 'https://d73c760c4e464b6313187ab045c27f68@o1115582.ingest.us.sentry.io/4507616077742080',
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled:
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_ENVIRONMENT === 'production',
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={frLocale}
    >
      <BrowserRouter>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={3000}
        >
          <Provider store={store}>
            <App />
          </Provider>
        </SnackbarProvider>
      </BrowserRouter>
    </LocalizationProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
